<template>
  <div eagle-form-item="offset"></div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
}
</script>

<style lang="sass" type="text/sass" scoped></style>
